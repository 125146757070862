<template>
  <div>
    <a-tabs v-model:activeKey="activeKey">
      <a-tab-pane key="1" :tab="$t('Info')">
        <div class="" style="margin-bottom: 50px">
          <div class="info">
            <h3>{{ $t("Info") }}</h3>
            <div class="type" style="margin-bottom: 10px">
              <span>{{ $t("Type") }}:</span>

              <span style="margin-left: 15px">
                <a-tag
                  v-if="data.type == 'sell'"
                  message="Sell"
                  color="green"
                  >{{ $t("Sell") }}</a-tag
                >

                <a-tag v-else message="Return" color="yellow">{{
                  $t("Return")
                }}</a-tag>
              </span>
              <span style="margin-left: 0px">
                <a-tag
                  v-if="data.remainingAmount == 0"
                  message="Paid"
                  color="blue"
                  >{{ $t("Paid") }}</a-tag
                >
                <a-tag v-else message="Return" color="orange">{{
                  $t("No Paid")
                }}</a-tag>
              </span>
              <span style="margin-left: 0px">
                <a-tag v-if="data.isReal" message="E-arşiv" color="red">{{
                  $t("E-arşiv")
                }}</a-tag>
              </span>
            </div>

            <div class="name" style="margin-bottom: 10px">
              <span
                >{{ $t("NO") }}: <b>{{ data.invoice_no }}</b></span
              >
            </div>

            <div class="branch" style="margin-bottom: 10px">
              <span
                >{{ $t("Branch") }}: <b>{{ data.branch_id.name }}</b></span
              >
            </div>

            <div class="customer" style="margin-bottom: 10px">
              <span
                >{{ $t("Customer") }}: <b>{{ data.customer_id.name }}</b></span
              >
            </div>

            <div class="branch" style="margin-bottom: 10px">
              <span
                >{{ $t("Date") }}:
                <b>{{ data.invoice_date.substr(0, 10) }}</b></span
              >
            </div>
            <div
              v-if="data.currencyRate"
              class="currencyRate"
              style="margin-bottom: 10px"
            >
              <span
                >{{ $t("Currency Rate") }}:
                <b>{{ moneyFormat(data.currencyRate).substr(1) }}</b></span
              >
            </div>
          </div>
          <a-divider v-if="data.desc" />
          <div class="desc" v-if="data.desc">
            <h3>{{ $t("Description") }}</h3>
            <div class="description" style="margin-bottom: 10px">
              <span>{{ data.desc }}</span>
            </div>
          </div>
          <a-divider />

          <div class="products">
            <h3>{{ $t("Products") }}</h3>

            <div class="product" style="margin-bottom: 10px">
              <span
                >{{ $t("Product Type") }}:
                <b>{{ `${data.products.length} ${$t("qty")}` }}</b></span
              >
            </div>

            <div class="product" style="margin-bottom: 10px">
              <span
                >{{ $t("Total Quantity") }}:

                <b>{{
                  data.products.reduce((accumulator, object) => {
                    return accumulator + object.quantity;
                  }, 0) + ` ${$t("qty")}`
                }}</b></span
              >
            </div>

            <div class="colors">
              <a-table
                :scroll="{ x: 300 }"
                :columns="columns"
                :data-source="columnsData"
                @change="onChange"
              >
                <template #bodyCell="{ column, text, record }">
                  <template v-if="column.key == 'sku'">
                    <a-tooltip>
                      <template #title>{{ record?.product_id?.name }}</template>
                      {{ record.sku }}
                    </a-tooltip>
                  </template>
                  <template v-if="column.key == 'price'">
                    <span
                      >{{ data.currency
                      }}{{ moneyFormat(record.price).substr(1) }}</span
                    >
                  </template>
                  <template v-if="column.key == 'quantity'">
                    <span>{{ record.quantity }}</span>
                  </template>
                  <template v-if="column.key == 'no_tax_price'">
                    <span
                      >{{ data.currency
                      }}{{ Number(record.no_tax_price).toFixed(2) }}</span
                    >
                  </template>
                  <template v-if="column.key == 'discount'">
                    <span v-if="record.discountType == '%'">{{
                      `%${record.discount}`
                    }}</span>
                    <span v-else>{{
                      `${data.currency}${Number(record.discount).toFixed(2)}`
                    }}</span>
                  </template>
                  <template v-if="column.key == 'tax'">
                    <span>{{ `%${record.tax}` }}</span>
                  </template>
                  <template v-if="column.key == 'total'">
                    <span
                      >{{ data.currency
                      }}{{
                        moneyFormat(
                          Number(record.quantity * record.price).toFixed(2)
                        ).substr(1)
                      }}</span
                    >
                  </template>
                </template>
              </a-table>
            </div>
          </div>
          <a-divider />
          <div class="price">
            <h3>{{ $t("Price") }}</h3>
            <a-list class="demo-loadmore-list" item-layout="horizontal">
              <a-list-item
                >{{ $t("SubTotal") }}:
                <b>{{
                  `${data.currency}${moneyFormat(data.subTotal).substr(1)}`
                }}</b>
              </a-list-item>
              <a-list-item
                >{{ $t("Tax") }}:
                <b>{{
                  `${data.currency}${moneyFormat(taxTotal()).substr(1)}`
                }}</b>
              </a-list-item>
              <!-- <a-list-item
                >{{ $t("Discount") }}:
                <b>{{
                  `${data.currency}${moneyFormat(discountTotal()).substr(1)}`
                }}</b>
              </a-list-item> -->
              <a-list-item v-if="data.discount"
                >{{ $t("Discount") }}:
                <b>{{
                  data.discountType == "%"
                    ? `-%${data.discount}`
                    : `-${data.currency}${data.discount}`
                }}</b>
              </a-list-item>
              <a-list-item
                >{{ $t("Amount") }}:
                <b>{{
                  `${data.currency}${moneyFormat(totalAmount()).substr(1)}`
                }}</b>
              </a-list-item>
            </a-list>
          </div>
          <a-divider v-if="data.remainingAmount > 0" />
          <div v-if="data.remainingAmount > 0" class="price">
            <h3>{{ $t("No Paid Amount") }}</h3>
            <a-list class="demo-loadmore-list" item-layout="horizontal">
              <a-list-item
                >{{ $t("Paid Amount") }}:
                <b>{{
                  `${data.currency}${moneyFormat(data.paidAmount).substr(1)}`
                }}</b>
              </a-list-item>
              <a-list-item
                >{{ $t("No Paid Amount") }}:
                <b>{{
                  `${data.currency}${moneyFormat(data.remainingAmount).substr(
                    1
                  )}`
                }}</b>
                <a-button
                  @click="showModalPayment"
                  style="margin-left: 15px"
                  type="primary"
                  >{{ $t("Pay") }}</a-button
                >
                <a-modal
                  :mask-closable="false"
                  v-model:visible="visiblePaymentModal"
                  :title="data.invoice_no"
                  :footer="false"
                >
                  <div class="">
                    <a-list class="demo-loadmore-list" item-layout="horizontal">
                      <a-list-item
                        >{{ $t("Amount") }}:
                        <b>{{
                          data.amount < 0
                            ? `-${data.currency}` +
                              moneyFormat(data.amount * -1).substr(1)
                            : `${data.currency}` +
                              moneyFormat(data.amount).substr(1)
                        }}</b>
                        <!-- <b>{{ `${data.currency}${moneyFormat(data.amount).substr(1)}` }}</b> -->
                      </a-list-item>
                      <a-list-item
                        >{{ $t("Paid Amount") }}:
                        <b>{{
                          `${data.currency}${moneyFormat(
                            data.paidAmount
                          ).substr(1)}`
                        }}</b>
                      </a-list-item>
                      <a-list-item
                        >{{ $t("No Paid Amount") }}:
                        <b>{{
                          `${data.currency}${moneyFormat(
                            data.remainingAmount
                          ).substr(1)}`
                        }}</b>
                      </a-list-item>
                      <a-list-item
                        >{{ $t("Date") }}:
                        <a-date-picker v-model:value="newPaidDate" />
                      </a-list-item>
                      <a-list-item
                        >{{ $t("Paid Amount") }}:
                        <a-input
                          v-model:value="newPaidAmount"
                          @change="newPaidAmountControl()"
                        />
                      </a-list-item>
                    </a-list>
                    <a-form-item>
                      <div
                        class=""
                        style="
                          display: flex;
                          justify-content: end;
                          align-items: center;
                        "
                      >
                        <a-button
                          @click="() => (visiblePaymentModal = false)"
                          >{{ $t("Cancel") }}</a-button
                        >
                        <a-popconfirm
                          :title="$t('Are you sure?')"
                          :ok-text="$t('Yes')"
                          :cancel-text="$t('No')"
                          @confirm="handleOkPayment()"
                          @cancel="cancel"
                        >
                          <a-button
                            :loading="newPaidLoad"
                            :disabled="
                              newPaidDate == null || newPaidAmount == 0
                            "
                            style="margin-left: 5px"
                            type="primary"
                            >{{ $t("Create") }}</a-button
                          >
                        </a-popconfirm>
                      </div>
                    </a-form-item>
                  </div>
                </a-modal>
              </a-list-item>
            </a-list>
          </div>
          <div
            :style="{
              position: 'absolute',
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e8e8e8',
              padding: '10px 16px',
              textAlign: 'right',
              left: 0,
              background: '#fff',
              borderRadius: '0 0 4px 4px',
            }"
          >
            <a-popconfirm
              v-if="user.earsiv && !data.isReal"
              :title="$t('Do you want to send it to GİB?')"
              :ok-text="$t('Yes')"
              :cancel-text="$t('No')"
              @confirm="sendGib(data._id)"
              @cancel="cancel"
            >
              <a-button
                v-if="user.earsiv"
                :disabled="data.isReal"
                :loading="printLoad"
                type="danger"
                @click=""
              >
                <SendOutlined />
                {{ data.isReal ? $t("Sended GİB") : $t("Send GİB") }}
              </a-button>
            </a-popconfirm>
            <a-button
              v-else
              v-if="user.earsiv"
              :disabled="data.isReal"
              :loading="printLoad"
              type="danger"
            >
              <SendOutlined />
              {{ data.isReal ? $t("Sended GİB") : $t("Send GİB") }}
            </a-button>

            <!-- <a-button
              style="margin-left: 15px"
              :loading="printLoad"
              type="primary"
              @click="selectPrinter(data._id)"
            >
              <PrinterOutlined />{{ $t("Print") }}
            </a-button>
           -->

            <a-button @click="visibleChangePrint" style="margin-right: 15px">
              <SwapOutlined />{{ $t("Change Printer") }}
            </a-button>
            <a-popover trigger="hover">
              <template #content>
                <span>{{ $t("if you want to print this data first") }} </span>
                <span style="margin: 0 10px"
                  ><a href="https://qz.io/download/" target="_blank"
                    >QZ Tray</a
                  ></span
                >
                <span>{{
                  $t(
                    "You need to download and run the program on your computer"
                  )
                }}</span>
              </template>
              <a-button
                :loading="printLoad"
                type="primary"
                @click="selectPrinter(data._id)"
              >
                <PrinterOutlined />{{ $t("Print") }}
              </a-button>
            </a-popover>
            <a-button
              style="margin-left: 5px"
              :loading="printLoad"
              type="primary"
              @click="pdf(data._id)"
            >
              {{ $t("PDF") }}
            </a-button>
            <a-modal
              v-model:visible="IsvisibleChangePrint"
              @cancel="handleCancelChangePrinter"
              :maskClosable="false"
              :title="$t('Change Printer')"
            >
              <a-form layout="vertical">
                <a-form-item :label="$t('Select Printer')">
                  <a-select
                    v-model:value="selectedPrinter"
                    :placeholder="$t('Select Printer')"
                  >
                    <a-select-option
                      v-for="(item, index) in printers"
                      :key="index"
                      :value="item"
                      >{{ item }}</a-select-option
                    >
                  </a-select>
                </a-form-item>
              </a-form>
              <template #footer>
                <a-button key="back" @click="handleCancelChangePrinter">{{
                  $t("Cancel")
                }}</a-button>
                <a-button
                  key="submit"
                  type="primary"
                  :disabled="selectedPrinter == ''"
                  :loading="printLoad"
                  @click="changePrinter()"
                  >{{ $t("Change") }}</a-button
                >
              </template>
            </a-modal>
            <a-modal v-model:visible="visible" :title="$t('Print')">
              <a-form layout="vertical">
                <a-form-item :label="$t('Select Printer')">
                  <a-select
                    v-model:value="selectedPrinter"
                    :placeholder="$t('Select Printer')"
                  >
                    <a-select-option
                      v-for="(item, index) in printers"
                      :key="index"
                      :value="item"
                      >{{ item }}</a-select-option
                    >
                  </a-select>
                </a-form-item>
              </a-form>
              <template #footer>
                <a-button key="back" @click="visible = false">{{
                  $t("Cancel")
                }}</a-button>
                <a-button
                  key="submit"
                  type="primary"
                  :disabled="selectedPrinter == ''"
                  :loading="printLoad"
                  @click="thermalPrint()"
                  >{{ $t("Print") }}</a-button
                >
              </template>
            </a-modal>
          </div>
        </div>
      </a-tab-pane>
      <a-tab-pane key="2" :tab="$t('Payment')">
        <div class="">
          <div class="" v-for="(item, index) in data.payments" :key="index">
            <a-card style="margin-top: 10px">
              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                "
              >
                <div>
                  <div class="">
                    <span
                      >{{ $t("Date") }}:
                      <b>{{ item.date.substr(0, 10) }}</b></span
                    >
                  </div>
                  <div class="">
                    <span
                      >{{ `${$t("Payment")}: `
                      }}<b>{{
                        `${data.currency}${moneyFormat(item.price).substr(1)}`
                      }}</b></span
                    >
                  </div>
                </div>
                <div>
                  <DeleteOutlined
                    @click="remove(item._id)"
                    style="cursor: pointer"
                  />
                </div>
              </div>
            </a-card>
          </div>
        </div>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import {
  PrinterOutlined,
  QuestionOutlined,
  SwapOutlined,
  SendOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons-vue";
import { notification, Modal } from "ant-design-vue";
import axios from "axios";
import qz from "qz-tray";
import dayjs from "dayjs";
import { createVNode } from "vue";

export default {
  props: ["data"],
  data() {
    return {
      activeKey: "1",
      visiblePaymentModal: false,
      columns: [
        {
          title: this.$t("SKU"),
          dataIndex: "products",
          key: "sku",
        },
        {
          title: this.$t("Quantity"),
          dataIndex: "products",
          key: "quantity",
        },
        {
          title: this.$t("No Tax Sale Price"),
          dataIndex: "products",
          key: "no_tax_price",
        },
        {
          title: this.$t("Discount"),
          dataIndex: "products",
          key: "discount",
        },
        {
          title: this.$t("Tax"),
          dataIndex: "products",
          key: "tax",
        },
        {
          title: this.$t("Price"),
          dataIndex: "products",
          key: "price",
        },

        {
          title: this.$t("Total"),
          dataIndex: "products",
          key: "total",
        },
      ],
      columnsData: this.data.products,
      printLoad: false,
      printers: [],
      visible: false,
      IsvisibleChangePrint: false,
      selectedPrinter: "",
      printedData: "",
      newPaidDate: null,
      newPaidAmount: 0,
      newPaidLoad: false,
      user: JSON.parse(localStorage.getItem("user")),
    };
  },
  created() {
    this.newPaidDate = dayjs();
  },
  methods: {
    async remove(id) {
      Modal.confirm({
        title: this.$t("Are you sure you want to delete?"),
        icon: createVNode(ExclamationCircleOutlined),
        content: this.$t(
          "If you delete this data, all related data will be deleted. Are you sure?"
        ),
        onOk: async () => {
          await axios
            .delete(`/api/payments/${id}`)
            .then(async (result) => {
              await axios
                .get(`/api/invoice/${this.data._id}`)
                .then(async (result) => {
                  await notification.success({
                    message: this.$t("Success"),
                    description: this.$t("Transaction Successfully Performed"),
                    duration: 1.5,
                  });
                  this.$emit("invoiceDetail", result.data);
                })
                .catch((err) => {
                  this.newPaidLoad = false;
                  console.log(err);
                });
            })
            .catch(async (err) => {});
        },
        onCancel() {},
      });
    },
    sendGib(id) {
      this.printLoad = true;
      axios
        .post(`/api/earsiv/create-invocie/${id}`)
        .then(async (res) => {
          await axios
            .get(`/api/invoice/${this.data._id}`)
            .then(async (result) => {
              await axios
                .get("/api/auth/user")
                .then(async (user) => {
                  await localStorage.setItem("user", JSON.stringify(user.data));
                  await notification.success({
                    message: this.$t("Success"),
                    description: this.$t(res.data.data),
                    duration: 5,
                  });
                  this.$emit("invoiceDetail", result.data);
                  this.printLoad = false;
                })
                .catch(async (err) => {
                  this.loading = false;
                  await notification.error({
                    message: this.$t("Error"),
                    description: this.$t("Error Occurred During Transaction"),
                    duration: 1.5,
                  });
                });
            })
            .catch((err) => {
              this.newPaidLoad = false;
              console.log(err);
            });
        })
        .catch(async (err) => {
          await notification.error({
            message: this.$t("Error"),
            description: this.$t(`${err.response.data.data}`),
            duration: 1.5,
          });
          this.printLoad = false;
        });
    },
    async connectToQz() {
      if (!qz.websocket.isActive()) {
        return qz.websocket
          .connect()
          .then(() => {
            console.log("QZ-Tray bağlantısı kuruldu.");
          })
          .catch((err) => {
            console.error("QZ-Tray bağlantısı kurulamadı:", err);
            throw new Error("QZ-Tray bağlantısı başarısız oldu.");
          });
      }
      return Promise.resolve();
    },
    replaceTurkishCharacters(text) {
      const charMap = {
        ç: "c",
        Ç: "C",
        ğ: "g",
        Ğ: "G",
        ı: "i",
        İ: "I",
        ö: "o",
        Ö: "O",
        ş: "s",
        Ş: "S",
        ü: "u",
        Ü: "U",
      };

      return text.replace(/[çÇğĞıİöÖşŞüÜ]/g, (char) => charMap[char] || char);
    },
    async thermalPrint() {
      await this.connectToQz();

      const data = await {
        products: this.data.products,
        invoice_no: this.data.invoice_no,
        invoice_date: this.data.invoice_date.substr(0, 10),
        branch: this.data.branch_id.name,
        customer: this.data.customer_id.name,
        subTotal: `${
          this.data.currency == "₺" ? "TL" : this.data.currency
        }${this.moneyFormat(this.data.subTotal).substr(1)}`,
        tax: `${
          this.data.currency == "₺" ? "TL" : this.data.currency
        }${this.moneyFormat(this.taxTotal()).substr(1)}`,
        discount: this.data.discount
          ? this.data.discountType == "%"
            ? `-%${this.data.discount}`
            : `-${this.data.currency == "₺" ? "TL" : this.data.currency}${
                this.data.discount
              }`
          : null,
        amount: `${
          this.data.currency == "₺" ? "TL" : this.data.currency
        }${this.moneyFormat(this.totalAmount()).substr(1)}`,
      };

      this.selectedPrinter = (await this.selectedPrinter)
        ? this.selectedPrinter
        : localStorage.getItem("thermal-printer");
      const config = await qz.configs.create("ZJ-58", { encoding: "CP857" });

      // Yazdırma formatı
      let receipt = this.replaceTurkishCharacters(`
\x1B\x61\x01
\x1B\x21\x30
${this.user.firm.name}
\x1B\x21\x00
\x1B\x61\x00
Şube: ${data.branch}
Müşteri: ${data.customer}
Fatura No: ${data.invoice_no}
Tarih: ${data.invoice_date}
------------------------------
Ürün         Adet        Fiyat
------------------------------
`);

      // Ürün bilgilerini ekleme
      data.products.forEach((product) => {
        receipt += this.replaceTurkishCharacters(
          `${product.product_id.name}\n` +
            `${product.sku.padEnd(15)} ${String(product.quantity).padEnd(
              6
            )} ${this.moneyFormat(product.price).substr(1)}\n\n`
        );
      });

      receipt += this.replaceTurkishCharacters(`
----------------------------
Ara Toplam: ${data.subTotal}
Vergi: ${data.tax}
${data.discount ? "İndirim: " + data.discount : ""}
----------------------------
Toplam: ${data.amount}
----------------------------
Teşekkür Ederiz!
\n\n \n\n`);
      const setTurkishCharset = "\x1B\x74\x13";
      // Yazıcıya gönder
      qz.print(config, [
        {
          type: "raw",
          format: "plain",
          data: setTurkishCharset + receipt,
        },
      ])
        .then(() => {
          console.log("Fiş başarıyla yazdırıldı.");
          notification.success({
            message: this.$t("Success"),
            description: this.$t("Receipt Printed Successfully"),
            duration: 1.5,
          });
          this.handleCancelChangePrinter();
        })
        .catch((err) => {
          this.handleCancelChangePrinter();
          console.error("Yazdırma hatası:", err);
          notification.error({
            message: this.$t("Error"),
            description: this.$t("Error Occurred While Printing"),
            duration: 1.5,
          });
        });
    },
    pdf(id) {
      this.printLoad = true;
      axios
        .get(`/api/invoice/generate-invoice-pdf/${id}`)
        .then((result) => {
          const pdfUrl = result.data.path; // PDF URL'sini buraya yerleştirin

          // PDF'yi indirmek için bir a etiketi oluşturun
          var link = document.createElement("a");
          link.href = pdfUrl;
          link.target = "_blank";
          link.click();
          this.printLoad = false;
        })
        .catch((err) => {
          this.printLoad = false;
          console.log(err);
        });
    },
    totalAmount() {
      var amount = Number(this.cartTotal()) + Number(this.taxTotal());
      if (this.data.discount > 0) {
        if (this.data.discountType == "%") {
          amount = amount - (amount * this.data.discount) / 100;
        } else {
          amount -= this.data.discount;
        }
      }
      return amount.toFixed(2);
    },
    discountTotal() {
      var total = 0;
      this.columnsData.forEach((e) => {
        if (e.discountType == "%") {
          total += ((e.no_tax_price * Number(e.discount)) / 100) * e.quantity;
        } else {
          total += Number(e.discount) * e.quantity;
        }
      });
      return total.toFixed(2);
    },
    cartTotal() {
      var total = 0;
      this.columnsData.forEach((e) => {
        var noTaxPrice = e.no_tax_price;
        if (e.discountType == "%") {
          noTaxPrice = noTaxPrice - (noTaxPrice * Number(e.discount)) / 100;
        } else {
          noTaxPrice -= Number(e.discount);
        }
        total = total + noTaxPrice * e.quantity;
      });
      return total.toFixed(2);
    },
    taxTotal() {
      var total = 0;
      this.columnsData.forEach((e) => {
        var noTaxPrice = e.no_tax_price;
        if (e.discountType == "%") {
          noTaxPrice = noTaxPrice - (noTaxPrice * Number(e.discount)) / 100;
        } else {
          noTaxPrice -= Number(e.discount);
        }
        total = total + ((noTaxPrice * e.tax) / 100) * e.quantity;
      });
      return total;
    },
    showModalPayment() {
      this.visiblePaymentModal = true;
    },
    handleOkPayment() {
      this.newPaidLoad = true;
      var data = {
        invoice_id: this.data._id,
        date: this.newPaidDate,
        price: this.newPaidAmount,
        currency: this.data.currency,
        customer_id: this.data.customer_id._id,
      };
      axios
        .post("api/invoice/payment", data)
        .then(async (res) => {
          await axios
            .get(`/api/invoice/${this.data._id}`)
            .then(async (result) => {
              await notification.success({
                message: this.$t("Success"),
                description: this.$t("Transaction Successfully Performed"),
                duration: 1.5,
              });
              this.visiblePaymentModal = false;
              this.$emit("invoiceDetail", result.data);
              this.newPaidLoad = false;
              this.newPaidAmount = 0;
              this.newPaidDate = dayjs();
            })
            .catch((err) => {
              this.newPaidLoad = false;
              console.log(err);
            });
        })
        .catch(async (err) => {
          await notification.error({
            message: this.$t("Error"),
            description: this.$t("Error Occurred During Transaction"),
            duration: 1.5,
          });
        });
    },
    newPaidAmountControl() {
      if (this.newPaidAmount > this.data.remainingAmount) {
        this.newPaidAmount = this.data.remainingAmount;
      }
    },
    async print(data) {
      this.printLoad = true;
      this.selectedPrinter = this.selectedPrinter
        ? this.selectedPrinter
        : localStorage.getItem("printer");
      var config = qz.configs.create(this.selectedPrinter);
      var invoice =
        '<div style="padding:10px;"><div><span style="font-size:24px;font-weight:600">' +
        this.user.firm.name +
        '</span></br><span style="font-size:18px;font-weight:600">' +
        this.data.branch_id.name +
        '</span></br><span>No: <span style="font-size:18px;font-weight:600">' +
        this.data.invoice_no +
        '</span></span></br><span>Fatura Türü: <span style="font-size:18px;font-weight:600">' +
        (this.data.type == "sell" ? "Satış" : "İade") +
        '</span></span></br><span>Tarih: <span style="font-size:18px;font-weight:600">' +
        this.data.invoice_date.substr(0, 10) +
        '</span></span><div style="margin-top:15px"><span>-----------------------------</span>';

      invoice +=
        '</div></div><div style="text-align:center;margin-top:15px;display:flex;justify-content:center;"><table style="width:100%;padding:0 15x"><thead><tr><th>Ürün</th><th>Adet</th><th>Fiyat</th></tr></thead><tbody>' +
        this.data.products.map(
          (e) =>
            `<tr><td>${e.sku}</td><td>${e.quantity}</td><td>${`${
              this.data.currency
            } ${this.moneyFormat(e.price).substr(1)}`}</td></tr>`
        ) +
        '</tbody></table></div><div style="margin-top:15px">';

      invoice +=
        '<span>-----------------------------</span></div><div style="margin-top:15px"><span>Ara Toplam: <span style="font-size:18px;font-weight:600">' +
        `${this.data.currency} ${this.moneyFormat(this.data.subTotal).substr(
          1
        )}` +
        '</span></span><div><div style="margin-top:15px"><span>Vergi: <span style="font-size:18px;font-weight:600">%' +
        `${this.data.tax}` +
        '</span></span><div><div style="margin-top:15px"><span>Ara Toplam: <span style="font-size:18px;font-weight:600">' +
        `${this.data.currency} ${this.moneyFormat(this.data.subAmount).substr(
          1
        )}` +
        '</span></span><div><div style="margin-top:15px">';
      if (this.data.discount) {
        invoice +=
          '<span>İndirim: <span style="font-size:18px;font-weight:600">' +
          `${
            this.data.discountType == "%"
              ? `-%${this.data.discount}`
              : `-${this.data.currency}${this.data.discount}`
          }` +
          "</span></span><div>";
      }

      invoice +=
        '<div style="margin-top:15px"><span>Toplam: <span style="font-size:18px;font-weight:600">' +
        `${this.data.currency}${this.moneyFormat(this.data.amount).substr(1)}` +
        "</span></span><div></div>";

      qz.print(config, [
        {
          type: "pixel",
          format: "html",
          flavor: "plain",
          data: invoice,
        },
      ])
        .then((result) => {
          this.printLoad = false;
          this.visible = false;
          qz.websocket.disconnect();
        })
        .catch((err) => {
          this.visible = false;
          this.printLoad = false;
          qz.websocket.disconnect();
        });
    },
    selectPrinter(id) {
      this.printers = [];
      this.printedData = "";
      qz.websocket
        .connect()
        .then(() => {
          return qz.printers.find();
        })
        .then(async (printers) => {
          var localPrinter = localStorage.getItem("thermal-printer");
          if (!localPrinter) {
            this.printers = printers;
            this.printedData = id;
            this.visible = true;
          } else {
            await this.thermalPrint();
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    visibleChangePrint() {
      this.printers = [];
      this.printedData = "";
      qz.websocket
        .connect()
        .then(() => {
          return qz.printers.find();
        })
        .then(async (printers) => {
          this.printers = printers;
          this.IsvisibleChangePrint = true;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    async changePrinter() {
      await localStorage.setItem("thermal-printer", this.selectedPrinter);
      await qz.websocket.disconnect();
      this.IsvisibleChangePrint = await false;
    },
    async handleCancelChangePrinter() {
      (this.IsvisibleChangePrint = await false),
        await qz.websocket.disconnect();
    },
    moneyFormat(number) {
      var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      });
      return formatter.format(number);
    },
  },
  components: {
    PrinterOutlined,
    SwapOutlined,
    QuestionOutlined,
    SendOutlined,
    DeleteOutlined,
    ExclamationCircleOutlined,
  },
};
</script>

<style lang="scss" scoped></style>
